import { ChartPoint } from "../../types";

export type OutputData = { [key: string]: any } & { date: string };

const transformData = (input: ChartPoint[]): OutputData[] => {
  // Create a map of date to name counts
  const dateToNameCounts = new Map<string, Map<string, number>>();
  input.forEach((item) => {
    const nameCounts = new Map<string, number>();
    item.data.forEach((dataItem) => {
      nameCounts.set(dataItem.name, dataItem.count);
    });
    dateToNameCounts.set(item.date, nameCounts);
  });

  // Get all unique names
  const allNames = new Set<string>();
  dateToNameCounts.forEach((nameCounts) => {
    nameCounts.forEach((count, name) => {
      allNames.add(name);
    });
  });

  // Create an array of output objects with all possible names for each date
  const output: OutputData[] = [];
  dateToNameCounts.forEach((nameCounts, date) => {
    const outputItem: OutputData = { date: date };
    allNames.forEach((name) => {
      outputItem[name] = nameCounts.get(name) || 0;
    });
    output.push(outputItem);
  });

  return output;
};

const transformDataDeltas = (input: ChartPoint[]): OutputData[] => {
  // Create a map of name to date counts
  const nameToDateCounts = new Map<string, Map<string, number>>();
  input.forEach((item) => {
    item.data.forEach((dataItem) => {
      const nameCounts =
        nameToDateCounts.get(dataItem.name) || new Map<string, number>();
      nameCounts.set(item.date, dataItem.count);
      nameToDateCounts.set(dataItem.name, nameCounts);
    });
  });

  // Get all unique names
  const allNames = Array.from(nameToDateCounts.keys());

  // Create an array of output objects with the difference between each date and the previous date for each name
  const output: OutputData[] = [];
  input.forEach((item, index) => {
    const outputItem: OutputData = { date: item.date };
    allNames.forEach((name) => {
      const nameCounts =
        nameToDateCounts.get(name) || new Map<string, number>();
      if (index === 0) {
        outputItem[name] = nameCounts.get(item.date) || 0;
      } else {
        const prevNameCounts =
          nameToDateCounts.get(name) || new Map<string, number>();
        const prevCount = prevNameCounts.get(input[index - 1].date) || 0;
        const currentCount = nameCounts.get(item.date) || 0;
        outputItem[name] = currentCount - prevCount;
      }
    });
    output.push(outputItem);
  });

  return output;
};

const addDateRange = (output: OutputData[]): OutputData[] => {
  const result: OutputData[] = [];

  // Iterate over each output object in the array
  for (let i = 0; i < output.length; i++) {
    const item = output[i];

    // Get the previous date (if any)
    const prevDate = i > 0 ? output[i - 1].date : "";

    // Create a new output object with the date range and add it to the result array
    const newItem: OutputData = {
      ...item,
      date: prevDate ? `${prevDate} to ${item.date}` : item.date,
    };
    result.push(newItem);
  }

  return result;
};

export { addDateRange, transformData, transformDataDeltas };
