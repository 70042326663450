import { useQuery } from "@apollo/client";
import { Box, Center, Divider } from "@chakra-ui/layout";
import { useParams } from "react-router";
import { QueryResult } from "../../components";
import { MESSAGE } from "../../graphql";
import { Message } from "../../types";
import { CompactMessageCard, FullMessageCard } from "../../utils";

const messageCard = (message: Message, brief: boolean) => {
  if (!message) {
    return <></>;
  }
  if (!brief) {
    return FullMessageCard(message);
  } else {
    return CompactMessageCard(message);
  }
};

export const MessagePage: React.FC = () => {
  const { id = "0" } = useParams();
  const { loading, error, data } = useQuery(MESSAGE, {
    variables: { id: parseInt(id) },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  return (
    <Center>
      <Box p={2} color="white" overflowX="auto">
        <QueryResult error={error} loading={loading} data={data}>
          <>
            {messageCard(data?.messageById, false)}
            {data?.messageById?.replies.length > 0 ? (
              <Center height="30px">
                <Divider orientation="horizontal" />
              </Center>
            ) : (
              <></>
            )}
            {data?.messageById?.replies.map((reply: Message) => {
              return messageCard(reply, true);
            })}
          </>
        </QueryResult>
      </Box>
    </Center>
  );
};
