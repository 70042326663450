import { Image } from "@chakra-ui/image";
import { Box, Grid, VStack } from "@chakra-ui/layout";
// import { ColorModeSwitcher } from "../../ColorModeSwitcher";

export const HomePage: React.FC = () => {
  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
        <VStack spacing={8}>
          <Image src="logo512.png" boxSize="512px" />
        </VStack>
      </Grid>
    </Box>
  );
};
