export const ColorsByPerson: { [name: string]: string } = {
  Brier: "#ff1493",
  Scott: "#ffff00",
  Jacqui: "#ba55d3",
  Joanna: "#0cd9e9",
  Cal: "#ce2520",
  Emil: "#64ac41",
  Brian: "#683e17",
  Jenna: "#6c6c6c",
  Erik: "#a6a6a6",
  Mike: "#83ddeb",
  Ash: "#d6d6d6",
  Lauren: "#e5e5e5",
  Jer: "#efefef",
  Lisa: "#f7f7f7",
  Dorothy: "#5a5a5a",
  Bobbi: "#fafafa",
  Laurel: "#b3b3b3",
  Fazel: "#6c6c6c",
  Jeremy: "#cfcfcf",
  Andrew: "#c2c2c2",
  Glen: "#ffffff"
};

export const ColorByPerson = (name: string) => {
  return ColorsByPerson[name] || "#000000";
};

const hexToRGB = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 0,
        g: 0,
        b: 0,
      };
};

export const TextColorForColor = (color: string) => {
  const rgb = hexToRGB(color);
  if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186) {
    return "#000000";
  } else {
    return "#ffffff";
  }
};
