import { useQuery } from "@apollo/client";
import { Box, Center, Heading, HStack } from "@chakra-ui/layout";
import { QueryResult } from "../../components";
import { HEAT } from "../../graphql";
import { Heat as HeatType } from "../../types";
import ActivityCalendar from "react-activity-calendar";
import ReactTooltip from "react-tooltip";
import { useNavigate, useParams } from "react-router";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Button } from "@chakra-ui/button";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const HeatPage: React.FC = () => {
  const navigate = useNavigate();
  const { who } = useParams();
  const { loading, error, data } = useQuery(HEAT, {
    variables: { who },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  return (
    <Center>
      <Box p={2} color="white" overflowX="auto">
        <HStack spacing={3}>
          <Heading as="h2" size="xl">
            Deadsquirrel Heat
          </Heading>
          <Box>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    Show me
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => navigate("/heat")}>
                      Everyone!
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/heat/Jacqui")}>
                      Jacqui
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/heat/Brier")}>
                      Brier
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/heat/Scott")}>
                      Scott
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/heat/Cal")}>
                      Cal
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/heat/Joanna")}>
                      Joanna
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/heat/Emil")}>
                      Emil
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/heat/Brian")}>
                      Brian
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/heat/Mike")}>
                      Mike
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </HStack>
        <QueryResult error={error} loading={loading} data={data}>
          <>
            <ReactTooltip html />
            <Box>
              {data?.numbersForHeat?.map((heat: HeatType) => (
                <Box key={"box" + heat.year}>
                  <ActivityCalendar
                    data={heat.heat}
                    key={"activity_calendar" + heat.year}
                    theme={{
                      level0: "#ffffff",
                      level1: "#f1ddcf",
                      level2: "#ffb6c1",
                      level3: "#ff69b4",
                      level4: "#ff1493",
                    }}
                    labels={{
                      tooltip: "{{count}} emails on {{date}}",
                    }}
                    eventHandlers={{
                      onClick: (event) => (data) => {
                        if (who && who.length > 0) {
                          navigate("/messages_on/" + data.date + "/" + who);
                        } else {
                          navigate("/messages_on/" + data.date);
                        }
                      },
                    }}
                  >
                    <ReactTooltip html />
                  </ActivityCalendar>
                  <br key={"br" + heat.year} />
                </Box>
              ))}
            </Box>
          </>
        </QueryResult>
      </Box>
    </Center>
  );
};
