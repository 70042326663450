import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "./appRoutes";
import { StatsAvailable } from "./types";
import { theme } from "./theme";
import { ToastContainer } from "react-toastify";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "mapbox-gl";
// @ts-ignore
mapboxgl.workerClass = MapboxWorker.default;

export const App = () => {
  let navigate = useNavigate();
  const token = localStorage.getItem("loginToken");
  const showMenu = token && token.length > 0;

  return (
    <ChakraProvider theme={theme}>
      <Flex flexDirection="column" h="100vh">
        {showMenu && (
          <HStack spacing={3}>
            <Image pl="16px" src="/logo192.png" boxSize="64px" />
            <Box>
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      isActive={isOpen}
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                    >
                      Menu
                    </MenuButton>
                    <MenuList>
                      {Object.keys(StatsAvailable).map(
                        (key: string, count = 0) => {
                          count += 1;
                          return (
                            <MenuItem
                              onClick={() => navigate("/stats/" + key)}
                              key={"menu" + count}
                            >
                              {StatsAvailable[key].title}
                            </MenuItem>
                          );
                        }
                      )}
                      <MenuItem onClick={() => navigate("/pardners")}>
                        Howdy Pardner
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={() => navigate("/heat")}>
                        Heat 🔥
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={() => navigate("/map")}>
                        Map 🌎
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={() => navigate("/chart")}>
                        Activity 📈
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/time_of_day")}>
                        ↳ by time of Day ⏰
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            </Box>
            <Button
              onClick={() =>
                window.open("http://ds.jobe.ca/stats/attachments2/", "_blank")
              }
            >
              Attachments
            </Button>
            <Button
              onClick={() =>
                navigate("/logout")
              }
            >
              Logout
            </Button>
          </HStack>
        )}
        <AppRoutes />
        <ToastContainer />
      </Flex>
    </ChakraProvider>
  );
};
