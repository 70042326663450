import { useQuery } from "@apollo/client";
import { Box, Center, Heading, Link } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/table";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import { QueryResult } from "../../components";
import { STATS } from "../../graphql";
import { Column, Stat, StatsAvailable } from "../../types";
import { Link as ReactRouterLink } from "react-router-dom";

export const TrFrom = (
  columns: Column[],
  stat: Stat,
  index: number,
  array: Stat[],
  which: string,
  word: string = ""
) => {
  const tds = columns.map((column: Column, td_index: number) => {
    return (
      <Td
        paddingInlineStart={3}
        paddingInlineEnd={3}
        isNumeric={column.isNumeric}
        key={"Td" + td_index.toString()}
        textOverflow={index === 2 ? "ellipsis" : ""}
      >
        {which === "wordCount" && td_index === 1 ? ( // LINK word counts to search results
          <Link
            as={ReactRouterLink}
            to={"/stats/wordCount/" + word + "/" + stat.description}
          >
            {column.value({ stat, index, array })}
          </Link>
        ) : which === "topSubjects" && td_index === 1 ? ( // LINK SUBJECTS TO INITIAL
          <Link
            as={ReactRouterLink}
            to={"/subject/" + encodeURIComponent(stat.description)}
          >
            {column.value({ stat, index, array })}
          </Link>
        ) : (
          // NO HYPERLINK
          column.value({ stat, index, array })
        )}
      </Td>
    );
  });

  return <Tr key={"Tr" + index.toString()}>{tds}</Tr>;
};

const inputsForStat = (
  which: string,
  value: string,
  navigate: NavigateFunction
) => {
  if (which !== "wordCount") {
    return <></>;
  }
  return (
    <>
      <br />
      <Input
        placeholder="word to search for"
        size="lg"
        defaultValue={value}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            console.log(value);
            navigate(`/stats/wordCount/${e.currentTarget.value}`);
          }
        }}
      />
      <br />
      <br />
    </>
  );
};

export const StatsPage: React.FC = () => {
  const navigate = useNavigate();
  const { which = "allTimeTopPosters", word = "" } = useParams();
  const [wordValue, setWordValue] = useState(word);
  const { loading, error, data } = useQuery(STATS, {
    variables: { which, word: wordValue },
    pollInterval: 60000,
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });
  const columns = StatsAvailable[which].columns;

  useEffect(() => {
    setWordValue(word);
  }, [word]);

  return (
    <Center>
      <Box p={2} color="white" overflowX="auto">
        <Heading as="h2" size="xl">
          {StatsAvailable[which].title}
        </Heading>
        {inputsForStat(which, wordValue, navigate)}
        <QueryResult error={error} loading={loading} data={data}>
          <TableContainer>
            <Table variant="striped" colorScheme="pink" w="100%" size="sm">
              <Thead>
                <Tr>
                  {columns.map((column: Column, index: number) => (
                    <Th
                      isNumeric={column.isNumeric}
                      key={"Th" + index.toString()}
                    >
                      {column.header}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {data?.statsFor?.map(
                  (stat: Stat, index: number, array: Stat[]) => {
                    return TrFrom(
                      columns,
                      stat,
                      index,
                      array,
                      which,
                      wordValue
                    );
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </QueryResult>
      </Box>
    </Center>
  );
};
