import { ApolloError } from "@apollo/client";
import { Box } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import styled from "@emotion/styled";
import React from "react";
import { Stat } from "../types";

const SpinnerContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh",
});

export const QueryResult = ({
  loading,
  error,
  data,
  children,
}: {
  loading: boolean;
  error: ApolloError | undefined;
  data: any[];
  children: JSX.Element;
}) => {
  if (error) {
    error.graphQLErrors.forEach(graphQLError => {
      if (graphQLError.extensions.code === "UNAUTHENTICATED") {
        localStorage.removeItem("loginToken");
        window.location.reload();
      }
    });
    return <Box>ERROR: {error.message}</Box>;
  }
  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </SpinnerContainer>
    );
  }
  if (data) {
    return children;
  }
  return <Box>No results :(</Box>;
};
