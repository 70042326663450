export const secondsToHumanReadable = (seconds: number): string => {
    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    const hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    const mnts = Math.floor(seconds / 60);
    seconds -= mnts * 60;

    // Using an array to hold parts of the converted time, for clarity and flexibility.
    const timeParts: string[] = [];
    if (days > 0) { timeParts.push(`${days}d`); }
    if (hrs > 0) { timeParts.push(`${hrs}h`); }
    if (mnts > 0) { timeParts.push(`${mnts}m`); }
    if (seconds > 0 || timeParts.length === 0) { timeParts.push(`${seconds}s`); }  // always show seconds if no other time units are present

    return timeParts.join(':');
}
