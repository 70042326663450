import { TimeOfDayValue } from "../../types";

export interface OutputData {
  [name: string]: number;
  hour: number;
}

export const transformData = (inputData: TimeOfDayValue[]): OutputData[] => {
  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  var names: string[] = [];

  for (var i = 0; i < inputData.length; i++) {
    var name = inputData[i].name;
    if (names.indexOf(name) === -1) {
      names.push(name);
    }
  }

  var result: OutputData[] = [];

  for (var j = 0; j < hours.length; j++) {
    var hour = hours[j];
    var outputData: OutputData = { hour: hour };

    for (var k = 0; k < names.length; k++) {
      var name2 = names[k];
      var countForNameAndHour = 0;
      for (var l = 0; l < inputData.length; l++) {
        if (inputData[l].name === name2 && inputData[l].hour === hour) {
          countForNameAndHour = inputData[l].count;
        }
      }
      outputData[name2] = countForNameAndHour;
    }
    result.push(outputData);
  }

  return result;
}