import { useQuery } from "@apollo/client";
import { Box, Text } from "@chakra-ui/layout";
import { QueryResult } from "../../components";
import { MAP } from "../../graphql";
import Map, { Marker, Popup } from "react-map-gl";
import { Location } from "../../types";
import MarkerIcon from "../../components/icons/marker.svg";
import CastleIcon from "../../components/icons/castle.svg";
import { useState } from "react";

export const MapPage: React.FC = () => {
  const { loading, error, data } = useQuery(MAP, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });
  const [popupInfo, setPopupInfo] = useState<Location | null>(null);

  var markers;
  var minimum_lat = 90,
    maximum_lat = -90,
    minimum_long = 180,
    maximum_long = -180;
  if (!loading) {
    markers = data?.locationsForMap?.map(
      (location: Location, index: number) => {
        if (location.coordinates.latitude < minimum_lat) {
          minimum_lat = location.coordinates.latitude;
        }
        if (location.coordinates.latitude > maximum_lat) {
          maximum_lat = location.coordinates.latitude;
        }
        if (location.coordinates.longitude < minimum_long) {
          minimum_long = location.coordinates.longitude;
        }
        if (location.coordinates.longitude > maximum_long) {
          maximum_long = location.coordinates.longitude;
        }
        return (
          <Marker
            longitude={location.coordinates.longitude}
            latitude={location.coordinates.latitude}
            key={"marker" + index.toString()}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              setPopupInfo(location);
            }}
          >
            <img
              src={location.icon === "marker" ? MarkerIcon : CastleIcon}
              style={{ height: 32, width: 32 }}
              alt={"Marker icon for " + location.description}
            />
          </Marker>
        );
      }
    );
  }

  return (
    <Box p={2} w="100%" h="100%">
      <QueryResult error={error} loading={loading} data={data}>
        <Map
          initialViewState={{
            longitude: (minimum_long + maximum_long) / 2.0,
            latitude: (minimum_lat + maximum_lat) / 2.0,
            zoom: 2,
          }}
          style={{ width: "100%", height: "100%" }}
          mapStyle="mapbox://styles/mapbox/outdoors-v11"
          mapboxAccessToken="pk.eyJ1Ijoiam9iZXVzIiwiYSI6ImNsOXJheDExejAwM2EzdnA2dmluZjg0aHoifQ.3uk-WpSW_EbhAJL0KS1S3w"
        >
          {markers}

          {popupInfo && (
            <Popup
              anchor="top"
              longitude={popupInfo.coordinates.longitude}
              latitude={popupInfo.coordinates.latitude}
              onClose={() => setPopupInfo(null)}
              closeButton={false}
            >
              <Box>
                <Text color="#000000">{popupInfo.description}</Text>
              </Box>
            </Popup>
          )}
        </Map>
      </QueryResult>
    </Box>
  );
};
