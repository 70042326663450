import { Center, Code, Divider, Heading, Link, Text } from "@chakra-ui/layout";
import { Card, CardBody } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Message } from "../types";
import { ColorByPerson, TextColorForColor } from "./colors";

const wrap = (s: string) =>
  s.replace(/(?![^\n]{1,80}$)([^\n]{1,80})\s/g, "$1\n");

export const FullMessageCard = (message: Message) => {
  const cardColor = ColorByPerson(message.name);
  const textColor = TextColorForColor(cardColor);
  if (!message) {
    return <></>;
  }
  return (
    <Card bg={cardColor} color={textColor} variant="elevated" w="3xl">
      <CardBody>
        {message.parent_id ? (
          <Link
            as={ReactRouterLink}
            to={"/message/" + message.parent_id.toString()}
          >
            <Heading as="h4" size="xs">
              ↑ parent
            </Heading>
          </Link>
        ) : (
          <></>
        )}
        <Heading as="h2" size="lg">
          {message.first ? "" : "RE: "}
          {message.subject}
        </Heading>
        <Heading as="h3" size="sm">
          From: {message.name}
        </Heading>
        <Heading as="h4" size="xs">
          At: {message.date}
        </Heading>
        <Center height="20px">
          <Divider orientation="horizontal" />
        </Center>
        <Code display="block" whiteSpace="pre" color={textColor}>
          {wrap(message.body)}
        </Code>
      </CardBody>
    </Card>
  );
};

export const CompactMessageCard = (message: Message) => {
  const cardColor = ColorByPerson(message.name);
  const textColor = TextColorForColor(cardColor);
  if (!message) {
    return <></>;
  }
  return (
    <>
      <Link as={ReactRouterLink} to={"/message/" + message.id.toString()}>
        <Card variant="elevated" w="md" bg={cardColor} color={textColor}>
          <CardBody>
            <Heading as="h3" size="sm">
              {message.name} @ {message.date}
            </Heading>
            <Text color={textColor} noOfLines={1}>
              {message.body}
            </Text>
          </CardBody>
        </Card>
      </Link>
      <br />
    </>
  );
};
