import { useQuery } from "@apollo/client";
import { Box, Center } from "@chakra-ui/layout";
import { useParams } from "react-router";
import { QueryResult } from "../../components";
import { MESSAGES_BY_SUBJECT } from "../../graphql";
import { Message } from "../../types";
import { CompactMessageCard } from "../../utils";

export const SubjectMessageListPage: React.FC = () => {
  const { subject } = useParams();
  const { loading, error, data } = useQuery(MESSAGES_BY_SUBJECT, {
    variables: { subject: subject },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  return (
    <Center>
      <Box p={2} color="white" overflowX="auto">
        <QueryResult error={error} loading={loading} data={data}>
          <>
            {data?.messagesBySubject?.map((message: Message) => {
              return CompactMessageCard(message);
            })}
          </>
        </QueryResult>
      </Box>
    </Center>
  );
};
