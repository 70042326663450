import { useQuery } from "@apollo/client";

import DatePicker from "react-datepicker";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
} from "recharts";
import { CHART_TIME_OF_DAY } from "../../graphql";
import { QueryResult } from "../../components";
import { forwardRef, useState } from "react";
import { DateTime } from "luxon";
import { Grid, GridItem } from "@chakra-ui/react";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Button } from "@chakra-ui/button";
import { OutputData, transformData } from "./helper";
import { ColorsByPerson } from "../../utils";
import { ChevronDownIcon } from "@chakra-ui/icons";

import "react-datepicker/dist/react-datepicker.css";

const convertHourToTimeRange = (hour: number): string => {
  const startHour = hour.toString().padStart(2, "0");
  const endHour = (hour + 1).toString().padStart(2, "0");
  return `${startHour}:00 to ${endHour}:00`;
};

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <Box bg="black" p={2}>
        <p className="label">{`${convertHourToTimeRange(parseInt(label))}`}</p>
        <Grid templateColumns="repeat(4, 1fr)" gap={2}>
          {payload.reverse().map((pld) => (
            <GridItem>
              <div style={{ display: "inline-block", padding: 2 }}>
                <div style={{ color: pld.color }}>{pld.value}</div>
                <div>{pld.dataKey}</div>
              </div>
            </GridItem>
          ))}
        </Grid>
      </Box>
    );
  }

  return null;
};

export const ChartTODPage: React.FC = () => {
  const epoch = DateTime.fromSQL("2001-11-11").toJSDate();
  const now = DateTime.now().toJSDate();

  const [who, setWho] = useState<string|undefined>(undefined);
  const [from, setFrom] = useState(DateTime.now().minus({ months: 6 }));
  const [to, setTo] = useState(DateTime.now());

  const { loading, error, data } = useQuery(CHART_TIME_OF_DAY, {
    variables: { from: from.toSQLDate(), to: to.toSQLDate(), who: (who === "Everyone" ? undefined : who) },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const ButtonInput = forwardRef<
    HTMLButtonElement,
    React.HTMLProps<HTMLButtonElement>
  >((props, ref) => (
    <Button onClick={props.onClick} ref={ref}>
      {props.value}
    </Button>
  ));

  let deltaData: OutputData[] | undefined;

  if (data) {
    deltaData = transformData(data?.dataForTimeOfDayChart);
  }

  return (
    <>
      <HStack spacing={4}>
        <Heading as="h2" size="xl">
          Time of day distribution for
        </Heading>
        <Box>
          <DatePicker
            showYearDropdown
            scrollableYearDropdown
            selected={from.toJSDate()}
            onChange={(date) => setFrom(DateTime.fromJSDate(date || epoch))}
            customInput={<ButtonInput />}
            minDate={epoch}
          />
        </Box>
        <Box>to</Box>
        <Box>
          <DatePicker
            showYearDropdown
            scrollableYearDropdown
            selected={to.toJSDate()}
            onChange={(date) => setTo(DateTime.fromJSDate(date || now))}
            customInput={<ButtonInput />}
            minDate={epoch}
          />
        </Box>
        <Box>, or unstretch for: </Box>
        <Box>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  Who ?
                </MenuButton>
                <MenuList>
                <MenuItem
                    onClick={() => {
                      setWho("Everyone");
                    }}
                  >
                    Everyone
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setWho("Brier");
                    }}
                  >
                    Brier
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setWho("Scott");
                    }}
                  >
                    Scott
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setWho("Jacqui");
                    }}
                  >
                    Jacqui
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setWho("Joanna");
                    }}
                  >
                    Joanna
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setWho("Cal");
                    }}
                  >
                    Cal
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setWho("Emil");
                    }}
                  >
                    Emil
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setWho("Brian");
                    }}
                  >
                    Brian
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Box>
      </HStack>
      <QueryResult error={error} loading={loading} data={data}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={deltaData}
            stackOffset={who ? "none": "expand"}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="1 5" />
            <XAxis dataKey="hour" />
            <YAxis domain={["dataMin", "dataMax"]} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {Object.keys(ColorsByPerson).map((name) => (
              <Bar
                key={name}
                type="monotone"
                dataKey={name}
                stackId="1"
                stroke={ColorsByPerson[name]}
                fill={ColorsByPerson[name]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </QueryResult>
    </>
  );
};
