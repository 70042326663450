import { Box } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {
  HeatPage,
  HomePage,
  MapPage,
  StatsPage,
  LoginPage,
  ChartPage,
  MessagePage,
  WordCountMessageListPage,
  DateMessageListPage,
  SubjectMessageListPage,
  ChartTODPage,
  PardnersPage,
} from "./pages";

const Loading = () => <Box>Loading...</Box>;

export const AppRoutes = () => {
  let navigate = useNavigate();

  const location = useLocation();
  const currentPath = location.pathname;

  const token = localStorage.getItem("loginToken");
  const showMenu = token && token.length > 0;

  useEffect(() => {
    if ((!showMenu && currentPath !== "/login") || (currentPath === "/logout")){
      localStorage.removeItem("loginToken");
      if (currentPath !== "/logout" && currentPath !== "/login") {
        localStorage.setItem("pre_login_path", currentPath);
      } else {
        localStorage.setItem("pre_login_path", "/stats/allTimeTopPosters");
      }
      navigate("/login");
    }
  }, [navigate, showMenu, currentPath]);

  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/stats" element={<StatsPage />} />
        <Route path="/stats/:which/:word" element={<StatsPage />} />
        <Route path="/stats/:which" element={<StatsPage />} />
        <Route
          path="/stats/wordCount/:word/:name"
          element={<WordCountMessageListPage />}
        />
        <Route path="/message/:id" element={<MessagePage />} />
        <Route path="/messages_on/:date/:name" element={<DateMessageListPage />} />
        <Route path="/messages_on/:date" element={<DateMessageListPage />} />
        <Route path="/subject/:subject" element={<SubjectMessageListPage />} />
        <Route path="/pardners" element={<PardnersPage />} />
        <Route path="/pardners/:author" element={<PardnersPage />} />
        <Route path="/heat" element={<HeatPage />} />
        <Route path="/heat/:who" element={<HeatPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/chart" element={<ChartPage />} />
        <Route path="/time_of_day" element={<ChartTODPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </React.Suspense>
  );
};
