import { useQuery } from "@apollo/client";

import DatePicker from "react-datepicker";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
} from "recharts";
import { CHART } from "../../graphql";
import { QueryResult } from "../../components";
import { forwardRef, useState } from "react";
import { DateTime } from "luxon";
import { Grid, GridItem } from "@chakra-ui/react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import { Button, IconButton } from "@chakra-ui/button";
import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  OutputData,
  addDateRange,
  transformData,
  transformDataDeltas,
} from "./helper";
import { ColorsByPerson } from "../../utils";

import "react-datepicker/dist/react-datepicker.css";

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <Box bg="black" p={2}>
        <p className="label">{`${label}`}</p>
        <Grid templateColumns="repeat(4, 1fr)" gap={2}>
          {payload.reverse().map((pld) => (
            <GridItem>
              <div style={{ display: "inline-block", padding: 2 }}>
                <div style={{ color: pld.color }}>{pld.value}</div>
                <div>{pld.dataKey}</div>
              </div>
            </GridItem>
          ))}
        </Grid>
      </Box>
    );
  }

  return null;
};
export const ChartPage: React.FC = () => {
  const mos_ago = DateTime.now().minus({ months: 6 }).toJSDate();

  const [segments, setSegments] = useState(10);
  const [since, setSince] = useState(mos_ago);
  const [lineType, setLineType] = useState(true);
  const [asPercentage, setAsPercentage] = useState(true);
  const [unstack, setUnstack] = useState(false);

  const { loading, error, data } = useQuery(CHART, {
    variables: { segments, since: DateTime.fromJSDate(since).toSQLDate() },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const ButtonInput = forwardRef<
    HTMLButtonElement,
    React.HTMLProps<HTMLButtonElement>
  >((props, ref) => (
    <Button onClick={props.onClick} ref={ref}>
      {props.value}
    </Button>
  ));

  let chartData: OutputData[] | undefined;
  let deltaData: OutputData[] | undefined;

  if (data && !deltaData) {
    deltaData = addDateRange(transformDataDeltas(data?.dataForChart));
    if (asPercentage) {
      deltaData = deltaData.slice(1);
    }
  }

  if (data && deltaData && !chartData) {
    if (asPercentage) {
      chartData = deltaData;
    } else {
      chartData = transformData(data?.dataForChart);
    }
  }

  return (
    <>
      <HStack>
        <Heading as="h2" size="xl">
          Since
        </Heading>
        <Box>
          <DatePicker
            showYearDropdown
            scrollableYearDropdown
            selected={since}
            onChange={(date) => setSince(date || mos_ago)}
            customInput={<ButtonInput />}
            minDate={new Date("2001/11/11")}
          />
        </Box>
        <Button onClick={() => setSince(new Date("2001/11/11"))}>
          All Time
        </Button>
        <Box>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  Resolution
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setSegments(10);
                    }}
                  >
                    10 (fastest)
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSegments(25);
                    }}
                  >
                    25
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSegments(50);
                    }}
                  >
                    50
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSegments(75);
                    }}
                  >
                    75
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSegments(100);
                    }}
                  >
                    100 (slowest)
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Box>
        <Box>
          Bar style : &nbsp;
          {lineType ? (
            <IconButton
              aria-label="Toggle Bar Graph"
              size="md"
              icon={<CheckIcon />}
              onClick={() => setLineType(!lineType)}
            />
          ) : (
            <IconButton
              colorScheme="teal"
              aria-label="Toggle Bar Graph"
              size="md"
              icon={<CheckIcon />}
              onClick={() => setLineType(!lineType)}
            />
          )}
        </Box>
        <Box>
          As percentages : &nbsp;
          {!asPercentage ? (
            <IconButton
              aria-label="As percentages"
              size="md"
              icon={<CheckIcon />}
              onClick={() => setAsPercentage(!asPercentage)}
            />
          ) : (
            <IconButton
              colorScheme="teal"
              aria-label="As percentages"
              size="md"
              icon={<CheckIcon />}
              onClick={() => setAsPercentage(!asPercentage)}
            />
          )}
        </Box>
        <Box>
          Unstack : &nbsp;
          {!unstack ? (
            <IconButton
              aria-label="Unstack"
              size="md"
              icon={<CheckIcon />}
              onClick={() => {
                if (!unstack) {
                  setAsPercentage(false);
                }
                setUnstack(!unstack)
              }}
            />
          ) : (
            <IconButton
              colorScheme="teal"
              aria-label="Unstack"
              size="md"
              icon={<CheckIcon />}
              onClick={() => {
                if (!unstack) {
                  setAsPercentage(false);
                }
                setUnstack(!unstack)
              }}
            />
          )}
        </Box>
      </HStack>
      <QueryResult error={error} loading={loading} data={data}>
        <ResponsiveContainer width="100%" height="100%">
          {lineType ? (
            <AreaChart
              data={chartData}
              stackOffset={asPercentage ? "expand" : "none"}
              margin={{
                top: 10,
                right: 30,
                left: 30,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="1 5" />
              <XAxis dataKey="date" />
              <YAxis domain={["dataMin", "dataMax"]} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              {Object.keys(ColorsByPerson).map((name, idx) => (
                <Area
                  key={name}
                  type="monotone"
                  dataKey={name}
                  stackId={unstack ? idx.toString() : "1"}
                  stroke={ColorsByPerson[name]}
                  fill={ColorsByPerson[name]}
                />
              ))}
            </AreaChart>
          ) : (
            <BarChart
              data={deltaData}
              stackOffset={asPercentage ? "expand" : "none"}
              margin={{
                top: 10,
                right: 30,
                left: 30,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="1 5" />
              <XAxis dataKey="date" />
              <YAxis domain={["dataMin", "dataMax"]} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              {Object.keys(ColorsByPerson).map((name, idx) => (
                <Bar
                  key={name}
                  type="monotone"
                  dataKey={name}
                  stackId={unstack ? idx.toString() : "1"}
                  stroke={ColorsByPerson[name]}
                  fill={ColorsByPerson[name]}
                />
              ))}
            </BarChart>
          )}
        </ResponsiveContainer>
      </QueryResult>
    </>
  );
};
