import { gql } from "@apollo/client";

export const STATS = gql`
  query getStats($which: String!, $word: String) {
    statsFor(stat: $which, word: $word) {
      description
      count
      date
      count_firsts
      days_since_last_message
    }
  }
`;

export const MESSAGE = gql`
  query getMessage($id: Int!) {
    messageById(id: $id) {
      id
      parent_id
      first
      subject
      name
      body
      date
      replies {
        id
        parent_id
        first
        subject
        name
        body
        date
      }
    }
  }
`;

export const MESSAGES_BY_NAME_WORD = gql`
  query getMessage($name: String!, $word: String!) {
    messagesByNameWord(name: $name, word: $word) {
      id
      parent_id
      first
      subject
      name
      body
      date
    }
  }
`;

export const MESSAGES_BY_DATE = gql`
  query getMessage($date: String!, $name: String) {
    messagesByDate(date: $date, name: $name) {
      id
      parent_id
      first
      subject
      name
      body
      date
    }
  }
`;

export const MESSAGES_BY_SUBJECT = gql`
  query getMessage($subject: String!) {
    messagesBySubject(subject: $subject) {
      id
      parent_id
      first
      subject
      name
      body
      date
    }
  }
`;

export const PARDNERS = gql`
  query getPardners($author: String) {
    responseTimeByAuthor(author: $author) {
      name
      avg_response_time_seconds
      count
    }
  }
`;

export const HEAT = gql`
  query getHeat($who: String) {
    numbersForHeat(who: $who) {
      year
      heat {
        date
        count
        level
      }
    }
  }
`;

export const MAP = gql`
  query getMap {
    locationsForMap {
      description
      coordinates {
        latitude
        longitude
      }
      icon
    }
  }
`;

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`;

export const CHART = gql`
  query dataForChart($segments: Int!, $since: String) {
    dataForChart(segments: $segments, since: $since) {
      date
      data {
        name
        count
      }
    }
  }
`;

export const CHART_TIME_OF_DAY = gql`
  query dataForTimeOfDayChart($from: String!, $to: String!, $who: String) {
    dataForTimeOfDayChart(from: $from, to: $to, who: $who) {
      name
      count
      hour
    }
  }
`;
