import { Level } from "react-activity-calendar";
import { secondsToHumanReadable } from "../utils";

export interface Stat {
  description: string;
  count: number;
  date?: string;
  count_firsts?: number;
  days_since_last_message?: number;
}

export interface Message {
  id: number;
  message_id: string;
  parent_id: number;
  first: boolean;
  subject: string;
  name: string;
  body: string;
  date: string;
  replies?: Message[];
}

export interface Column {
  header: string;
  isNumeric: boolean;
  value: StatsValue;
}

export interface StatDisplay {
  title: string;
  columns: Column[];
}

export interface StatsValueProps {
  stat: Stat;
  index: number;
  array: Stat[];
}

export interface ResponseTimeValue {
  name: string;
  avg_response_time_seconds: number;
  count: number
}

export interface Location {
  description: string;
  coordinates: Coordinates;
  icon: string;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface ChartPoint {
  date: string;
  data: ChartData[];
}

export interface ChartData {
  name: string;
  count: number;
}

export interface TimeOfDayValue {
  name: string;
  count: number;
  hour: number;
}

export type StatsValue = (props: any) => string;

export interface Heat {
  year: number;
  heat: HeatDate[];
}

export interface HeatDate {
  date: string;
  count: number;
  level: Level;
}

const defaultSubjectColumns = [
  {
    header: "",
    isNumeric: true,
    value: (props: StatsValueProps) => (props.index + 1).toString(),
  },
  {
    header: "Subject",
    isNumeric: false,
    value: (props: StatsValueProps) => props.stat.description,
  },
  {
    header: "Posts",
    isNumeric: true,
    value: (props: StatsValueProps) => props.stat.count.toString(),
  },
  {
    header: "Δ",
    isNumeric: true,
    value: (props: StatsValueProps) =>
      props.index === 0
        ? "0"
        : (props.stat.count - props.array[props.index - 1].count).toString(),
  },
];

const defaultPersonColumns = [
  {
    header: "",
    isNumeric: true,
    value: (props: StatsValueProps) => (props.index + 1).toString(),
  },
  {
    header: "Who",
    isNumeric: false,
    value: (props: StatsValueProps) => props.stat.description,
  },
  {
    header: "Posts",
    isNumeric: true,
    value: (props: StatsValueProps) => props.stat.count.toString(),
  },
  {
    header: "Δ",
    isNumeric: true,
    value: (props: StatsValueProps) =>
      props.index === 0
        ? "0"
        : (props.stat.count - props.array[props.index - 1].count).toString(),
  },
  {
    header: "🆕",
    isNumeric: true,
    value: (props: StatsValueProps) =>
      props.stat.count_firsts ? props.stat.count_firsts.toString() : "N/A",
  },
  {
    header: "% 🆕",
    isNumeric: true,
    value: (props: StatsValueProps) =>
      props.stat.count_firsts
        ? props.stat.count_firsts === props.stat.count
          ? "100"
          : ((props.stat.count_firsts / props.stat.count) * 100.0)
              .toFixed(1)
              .toString()
        : "N/A",
  },
];

const streakColumns = [
  {
    header: "",
    isNumeric: true,
    value: (props: any) => (props.index + 1).toString(),
  },
  {
    header: "Who",
    isNumeric: false,
    value: (props: any) => props.stat.description,
  },
  {
    header: "Posts",
    isNumeric: true,
    value: (props: any) => props.stat.count.toString(),
  },
  {
    header: "When",
    isNumeric: false,
    value: (props: any) => props.stat.date || "",
  },
];

const responseTimeColumns = [
  {
    header: "Who",
    isNumeric: false,
    value: (props: any) => props.name,
  },
  {
    header: "~Response time",
    isNumeric: false,
    value: (props: any) => secondsToHumanReadable(props.avg_response_time_seconds),
  },
  {
    header: "# of replies",
    isNumeric: true,
    value: (props: any) => props.count,
  },
];

export const ResponseTimeStat: StatDisplay = {
  title: "Who's quickest to draw on who?",
  columns: responseTimeColumns,
};

export const StatsAvailable: { [key: string]: StatDisplay } = {
  allTimeTopPosters: {
    title: "All-time Top Posters",
    columns: defaultPersonColumns,
  },
  thisYearTopPosters: {
    title: "This Year Top Posters",
    columns: defaultPersonColumns,
  },
  thisMonthTopPosters: {
    title: "This Month Top Posters",
    columns: defaultPersonColumns,
  },
  last100DaysTopPosters: {
    title: "Last 100 days Top Posters",
    columns: defaultPersonColumns,
  },
  last24HoursTopPosters: {
    title: "Last 24h Top Posters",
    columns: defaultPersonColumns,
  },
  todayTopPosters: {
    title: "Today (Mtn time) Top Posters",
    columns: defaultPersonColumns,
  },
  streaksByIndividual: {
    title: "Top Uninterrupted Streaks by Poster",
    columns: streakColumns,
  },
  streaksAllTime: {
    title: "Top Uninterrupted Streaks (All-Time)",
    columns: streakColumns,
  },
  wordCount: {
    title: "Word Usage / Search",
    columns: defaultPersonColumns.slice(0, 3),
  },
  topSubjects: {
    title: "Top Subjects",
    columns: defaultSubjectColumns,
  }
};
