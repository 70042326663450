import { useQuery } from "@apollo/client";
import { Box, Center, Heading } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/react";
import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/table";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import { QueryResult } from "../../components";
import { PARDNERS } from "../../graphql";
import { Column, ResponseTimeStat, ResponseTimeValue } from "../../types";

interface WhoForResponsesProps {
    authorValue?: string;
    navigate: NavigateFunction;
}

const WhoForResponses: React.FC<WhoForResponsesProps> = ({ authorValue, navigate }) => {
    const authors = ['Jacqui', 'Brier', 'Scott', 'Cal', 'Joanna', 'Emil', 'Brian', 'Mike', 'BirthdayBot'];

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const author = event.target.value;
        navigate(`/pardners/${author}`);
    };

    return (
        <Select
            bg='tomato'
            borderColor='tomato'
            color='white'
            placeholder='Everyone!'
            value={authorValue}
            onChange={handleChange}
        >
            {authors.map(author => (
                <option key={author} value={author}>
                    {author}
                </option>
            ))}
        </Select>
    );
}

export const TrFrom = (
    columns: Column[],
    response_time: ResponseTimeValue,
    index: number,
) => {
    const tds = columns.map((column: Column, td_index: number) => {
        return (
            <Td
                paddingInlineStart={3}
                paddingInlineEnd={3}
                isNumeric={column.isNumeric}
                key={"Td" + td_index.toString()}
                textOverflow={index === 2 ? "ellipsis" : ""}
                textAlign={td_index === 1 ? "right" : "left"}
            >
                {column.value(response_time)}
            </Td>
        );
    });

    return <Tr key={"Tr" + index.toString()}>{tds}</Tr>;
};

export const PardnersPage: React.FC = () => {
    const navigate = useNavigate();
    const { author = undefined } = useParams();
    const [authorValue, setAuthorValue] = useState(author);
    const { loading, error, data } = useQuery(PARDNERS, {
        variables: { author: authorValue },
        pollInterval: 60000,
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first',
        errorPolicy: 'all',
    });
    const columns = ResponseTimeStat.columns;

    useEffect(() => {
        setAuthorValue(author);
    }, [author]);

    return (
        <Center>
            <Box p={2} color="white" overflowX="auto">
                <Heading as="h2" size="xl">
                    {ResponseTimeStat.title}
                </Heading>
                <WhoForResponses authorValue={authorValue} navigate={navigate} />
                <QueryResult error={error} loading={loading} data={data}>
                    <TableContainer>
                        <Table variant="striped" colorScheme="pink" w="100%" size="sm">
                            <Thead>
                                <Tr>
                                    {columns.map((column: Column, index: number) => (
                                        <Th
                                            isNumeric={column.isNumeric}
                                            key={"Th" + index.toString()}
                                        >
                                            {column.header}
                                        </Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                {data?.responseTimeByAuthor?.map(
                  (response_time: ResponseTimeValue, index: number) => {
                    return TrFrom(
                      columns,
                      response_time,
                      index,
                    );
                  }
                )}
              </Tbody>                        </Table>
                    </TableContainer>
                </QueryResult>
            </Box>
        </Center>
    );
};
