import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { LOGIN } from "../../graphql";
import { Button, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

export const LoginPage: React.FC = () => {
  const [login, { data }] = useMutation(LOGIN);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const token = data?.login || localStorage.getItem("loginToken");
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login({ variables: { username, password } })
      .then(({ data }) => {
        if (data.errors) {
          toast(data.errors[0].message);
        }
      })
      .catch((e) => {
        toast.error("🦄 " + e.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };

  React.useEffect(() => {
    if (token) {
      localStorage.setItem("loginToken", token);
    }
    if (token && token.length > 0) {
      const pre_login_path = localStorage.getItem("pre_login_path");
      if (pre_login_path) {
        localStorage.removeItem("pre_login_path");
        navigate(pre_login_path);
      } else {
        navigate("/stats");
      }
    }
  }, [navigate, token]);

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel htmlFor="username">Username</FormLabel>
          <Input
            id="username"
            name="username"
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormControl>
        <Button type="submit">Login</Button>
      </Stack>
    </form>
  );
};
